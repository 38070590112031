export const convertTime = totalSeconds => {
  const s = totalSeconds % 60;
  const m = parseInt(totalSeconds / 60, 10) % 60;
  const h = parseInt(totalSeconds / 3600, 10);

  return `${h}h ${m}m ${s}s`;
};

export const convertMetersToMiles = meters =>
  (convertMetersToFeet(meters) / 5280).toFixed(2);

export const convertMetersToFeet = meters => Math.round(meters * 3.2808);
