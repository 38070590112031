import React from 'react';
import { graphql } from 'gatsby';
import polyline from '@mapbox/polyline';

import Totals from '../components/totals';
import Layout from '../components/layout';
import SEO from '../components/seo';

import Card from '../components/card';

import style from './pages.module.scss';

const IndexPage = ({ data }) => {
  const getCoords = (prev, curr) => {
    const defaultC = prev ? prev : [];
    return [...curr, ...defaultC];
  };
  const activities = data.allMarkdownRemark.edges.map(post => {
    const stravaData = data.allStravaActivity.edges.filter(strava => {
      return post.node.frontmatter.activityId.includes(strava.node.activity.id);
    });

    const polylinePrefix =
      post.node.frontmatter.dayNumber % 2 === 0
        ? '&path=color:0x333FFFff%7Cweight:2%7Cenc:'
        : '&path=color:0xFC1B17ff%7Cweight:2%7Cenc:';

    return {
      ...post.node,
      stravaData: stravaData[0].node.activity,
      totals: stravaData.reduce((acc, cv) => {
        const currCoords = polyline
          .decode(cv.node.activity.map.summary_polyline)
          .map(point => {
            return {
              lat: point[0],
              lng: point[1],
            };
          });
        return {
          mapPolyline:
            (acc.mapPolyline || '') +
            polylinePrefix +
            cv.node.activity.map.summary_polyline,
          distance: (acc.distance || 0) + cv.node.activity.distance,
          total_elevation_gain:
            (acc.total_elevation_gain || 0) +
            cv.node.activity.total_elevation_gain,
          moving_time: (acc.moving_time || 0) + cv.node.activity.moving_time,
          coords: getCoords(acc.coords, currCoords),
        };
      }, {}),
    };
  });

  const posts = activities.map(activity => {
    return (
      <Card
        key={activity.id}
        activity={activity}
        mapsKey={data.site.siteMetadata.googleMapsKey}
      ></Card>
    );
  });

  const totals = activities
    .sort((a, b) => a.frontmatter.dayNumber - b.frontmatter.dayNumber)
    .reduce((acc, cv) => {
      return {
        mapPolyline:
          cv.stravaData.id !== 2694759686
            ? (acc.mapPolyline || '') + cv.totals.mapPolyline
            : acc.mapPolyline || '',
        distance: (acc.distance || 0) + cv.totals.distance,
        total_elevation_gain:
          (acc.total_elevation_gain || 0) + cv.totals.total_elevation_gain,
        moving_time: (acc.moving_time || 0) + cv.totals.moving_time,
        stravaLink: 'https://www.strava.com/athletes/313909',
        fullPoly: acc.fullPoly
          ? [...acc.fullPoly, ...cv.totals.coords]
          : cv.totals.coords,
      };
    }, {});

  return (
    <Layout>
      <SEO
        title="Rob Lea – Ultimate World Triathlon"
        useTitleTemplate={false}
      />
      <div className={style.hero}>&nbsp;</div>
      <div className={[style.page, style.page__homepage].join(' ')}>
        <div className={style.feature_odd}>
          <div className={[style.container, style.container_intro].join(' ')}>
            <h1>Ride Across America</h1>
            <p>
              Follow Rob's progress as he rides over 3,500 miles across America
              from Anacortes, WA to Nantucket, MA.
            </p>
            <p className={style.update}>
              <b>Last Update:</b> Tue, Oct 8 at 8:15pm MT ... RIDE FINISHED!!!
            </p>
          </div>
        </div>
        <div className={style.feature}>
          <div className={style.container}>
            <h2>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="biking"
                className="svg-inline--fa fa-biking fa-w-20"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
              >
                <path
                  fill="currentColor"
                  d="M400 96a48 48 0 1 0-48-48 48 48 0 0 0 48 48zM128 256a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 208a80 80 0 1 1 80-80 80.09 80.09 0 0 1-80 80zm384-208a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 208a80 80 0 1 1 80-80 80.09 80.09 0 0 1-80 80zM401 210.73a24 24 0 0 0 15 5.27h64a24 24 0 0 0 0-48h-55.59L351 109.27a24 24 0 0 0-30.62.51l-104 89.11a32 32 0 0 0 3.06 50.94l76.53 51V416a24 24 0 0 0 48 0V288a24 24 0 0 0-10.69-20l-50.11-33.4 71.29-61.1z"
                ></path>
              </svg>
              The Journey
            </h2>
            <Totals
              data={totals}
              activities={activities}
              googleMapsKey={data.site.siteMetadata.googleMapsKey}
            />
          </div>
        </div>
        <div className={style.feature_odd}>
          <div className={style.container}>
            <h2>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="biking"
                className="svg-inline--fa fa-biking fa-w-20"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
              >
                <path
                  fill="currentColor"
                  d="M400 96a48 48 0 1 0-48-48 48 48 0 0 0 48 48zM128 256a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 208a80 80 0 1 1 80-80 80.09 80.09 0 0 1-80 80zm384-208a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 208a80 80 0 1 1 80-80 80.09 80.09 0 0 1-80 80zM401 210.73a24 24 0 0 0 15 5.27h64a24 24 0 0 0 0-48h-55.59L351 109.27a24 24 0 0 0-30.62.51l-104 89.11a32 32 0 0 0 3.06 50.94l76.53 51V416a24 24 0 0 0 48 0V288a24 24 0 0 0-10.69-20l-50.11-33.4 71.29-61.1z"
                ></path>
              </svg>
              Daily Recap + Photos
            </h2>
            <div className={style.postContainer}>{posts}</div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        googleMapsKey
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___dayNumber] }
    ) {
      totalCount
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            dayNumber
            activityId
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    allStravaActivity {
      totalCount
      edges {
        node {
          activity {
            id
            name
            start_date
            distance
            moving_time
            map {
              summary_polyline
            }
            total_elevation_gain
          }
        }
      }
    }
  }
`;

export default IndexPage;
