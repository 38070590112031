import React from 'react';
import GoogleMap from 'google-map-react';

const Map = ({ googleMapsKey, activities, totals }) => {
  const fitBounds = (map, maps) => {
    var bounds = new maps.LatLngBounds();
    for (let marker of totals.fullPoly) {
      bounds.extend(new maps.LatLng(marker.lat, marker.lng));
    }
    map.fitBounds(bounds);
  };

  const renderPolylines = (map, maps) => {
    activities.map(activity => {
      const polylineColor =
        activity.frontmatter.dayNumber % 2 === 0 ? '#333FFF' : '#FC1B17';

      const nonGeodesicPolyline = new maps.Polyline({
        path: activity.totals.coords,
        geodesic: false,
        strokeColor: polylineColor,
        strokeOpacity: 1,
        strokeWeight: 3,
      });
      nonGeodesicPolyline.setMap(map);
      return null;
    });
    fitBounds(map, maps);
  };

  return (
    <GoogleMap
      bootstrapURLKeys={{ key: googleMapsKey }}
      defaultCenter={[48.1457, -116.17654]}
      defaultZoom={6}
      onGoogleApiLoaded={({ map, maps }) => renderPolylines(map, maps)}
      yesIWantToUseGoogleMapApiInternals={true}
    />
  );
};

export default Map;
