import React from 'react';
import { Link } from 'gatsby';
import style from './card.module.scss';

import {
  convertMetersToMiles,
  convertMetersToFeet,
  convertTime,
} from '../helpers/conversions';

const Card = ({ activity, mapsKey }) => {
  const { frontmatter, fields, totals, excerpt } = activity;

  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?size=640x320&scale=1&maptype=roadmap&path=color:0x333333ff%7Cweight:2%7Cenc:${totals.mapPolyline}&format=jpg&key=${mapsKey}`;

  const readMoreLink = () => <Link to={fields.slug}>&nbsp;[Read More]</Link>;
  return (
    <div className={style.card}>
      <Link to={fields.slug}>
        <img src={mapUrl} alt="Map of Route" />
      </Link>
      <div className={style.card_container}>
        <h4>
          <Link
            to={fields.slug}
          >{`Day ${frontmatter.dayNumber}: ${frontmatter.title}`}</Link>
        </h4>
        <p className={style.card_stats}>
          {`${convertMetersToMiles(totals.distance).toLocaleString()} mi`}{' '}
          <span>|</span>{' '}
          {`${convertMetersToFeet(
            totals.total_elevation_gain
          ).toLocaleString()} ft elev`}{' '}
          <span>|</span> {`${convertTime(totals.moving_time)}`}
        </p>
        <p className={style.card_excerpt}>
          {excerpt}
          {readMoreLink()}
        </p>
      </div>
    </div>
  );
};

export default Card;
