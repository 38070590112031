import React from 'react';
import style from './totals.module.scss';
import Map from './map';

import {
  convertMetersToMiles,
  convertMetersToFeet,
  convertTime,
} from '../helpers/conversions';

const Totals = ({ data, googleMapsKey, activities }) => {
  const { distance, moving_time, total_elevation_gain, stravaLink } = data;

  return (
    <div className={style.totals}>
      <div style={{ width: '100%', height: 450 }}>
        <Map
          googleMapsKey={googleMapsKey}
          activities={activities}
          totals={data}
        />
      </div>
      <div className={style.stats}>
        <div className={style.stat}>
          <span>Distance</span>
          {`${convertMetersToMiles(distance).toLocaleString()} mi`}
        </div>
        <div className={style.stat}>
          <span>Time</span>
          {`${convertTime(moving_time)}`}
        </div>
        <div className={style.stat}>
          <span>Elevation Gain</span>
          {`${convertMetersToFeet(total_elevation_gain).toLocaleString()} ft`}
        </div>
      </div>
      <p className={style.stravaLink}>
        <a href={stravaLink} target="_blank" rel="noopener noreferrer">
          View on Strava
        </a>
      </p>
    </div>
  );
};

export default Totals;
